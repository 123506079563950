import '../styles/about.scss';
import { useContext } from 'react';
import LangContext from '../contexts/LangContext';
import team1 from '../assets/team1.png';
import team2 from '../assets/team2.png';

export default function AboutPage() {
  const { lang } = useContext(LangContext);

  return <>
    <main className="container">
      <section className="about__company">
        <div className="heading">
          <h2>{lang === 'en' ? 'About us' : 'Sobre nós'}</h2>
          <span className="line"></span>
          <p>{
            lang === 'en' ?
              "We are a family company. We believe that we can provide a quality service, in a sustainable way and suitable for our target market: small and medium sized companies. Often, these companies just need a superior quality service, at an investment cost appropriate to their business model." :
              "Somos uma empresa familiar. Acreditamos que podemos prestar um serviço de qualidade, de forma sustentável e adequado ao nosso mercado alvo: pequenas e médias empresas. Muitas vezes, estas empresas apenas precisam de um serviço de qualidade superior, a um custo de investimento adequado ao seu modelo de negócio."
          }</p>
        </div>

        <div className="goals">
          <div className="goal">
            <div className="head">
              <i className='bx bxs-chess' ></i>
            </div>
            <div className="content">
              <h4>{lang === 'en' ? 'Strategy' : 'Estratégia'}</h4>
              <p>{
                lang === 'en' ?
                  "Our strategic mission and vision is the success of our customers, thus enabling small and medium-sized companies to enjoy high quality services that they would otherwise not have access to for the investment that would be required." :
                  "Temos como missão e visão estratégicas o sucesso dos nossos clientes, permitindo, assim, às pequenas e médias empresas usufruir de serviços de alta qualidade a que de outra forma não teriam acesso pelo investimento que seria necessário."
              }</p>
            </div>
          </div>

          <div className="goal">
            <div className="head">
              <i className='bx bx-recycle' ></i>
            </div>
            <div className="content">
              <h4>{lang === 'en' ? 'Sustainable' : 'Sustentável'}</h4>
              <p>{
                lang === 'en' ?
                  "We understand our customers' difficulties and are willing to share the risk of investment in transforming their business, thus making us an integral part of their success. We present sustainable solutions as opposed to the temptation to solve a problem on a temporary basis. We are part of the Community!" :
                  "Entendemos as dificuldades dos nossos clientes e estamos dispostos a partilhar o risco do investimento na transformação do seu negócio, tornando-nos, assim, parte integrante do seu sucesso. Apresentamos soluções sustentáveis por oposição à tentação de resolver um problema de forma temporária. Somos parte da Comunidade!"
              }</p>
            </div>
          </div>

          <div className="goal">
            <div className="head">
              <i className='bx bx-trending-up' ></i>
            </div>
            <div className="content">
              <h4>{lang === 'en' ? 'Continuous Improvement' : 'Melhoria Contínua'}</h4>
              <p>{
                lang === 'en' ?
                  "We want to make it happen, review, evaluate, and improve again - those are our guiding principles." :
                  "Queremos fazer acontecer, rever, avaliar e tornar a melhorar - são os nossos principios orientadores."
              }</p>
            </div>
          </div>

          <div className="goal">
            <div className="head">
              <i className='bx bx-notepad' ></i>
            </div>
            <div className="content">
              <h4>{lang === 'en' ? 'Oriented to Results' : 'Orientada a Resultados'}</h4>
              <p>{
                lang === 'en' ?
                  "We are a results-oriented team with a dynamic of transformation and innovation!" :
                  "Somos uma equipa orientada a resultados com uma dinâmica de transformação e inovação!"
              }</p>
            </div>
          </div>
        </div>
      </section>

      <section className="about__team">
        <div className="heading">
          <h2>{lang === 'en' ? 'The Team' : 'A Equipa'}</h2>
          <span className="line"></span>
        </div>

        <div className="team">
          <div className="person">
            <div className="img">
              <img src={team1} alt="" />
            </div>
            <span className="line"></span>
            <div className="content">
              <span>Nuno Oliveira</span>
              <p>{
                lang === 'en' ?
                  <>Nuno is an entrepreneur and senior manager, having experience as General Manager, Manager of complex organizational/ operational transformation initiatives and programs, acting as a strategic leader and change agent, supporting companies in their evolution and adaptation to the market.<br />With several years in Consulting and experience in several national and international organizations, he is seen as a trusted partner at the executive level, as well as a leader in building and motivating teams.<br />His vast international experience, demonstrating high levels of technological knowledge with a realistic understanding of market trends and vision/mission of organizations, combined with an expertise in strategic vision and building transformation roadmaps, are his best "business card".<br />He dedicates part of his time to supporting startups and small entrepreneurs/businesses, as well as supporting social development and sustainability causes.</> :
                  <>O Nuno é um empreendedor e gestor senior, tendo experiência como Director Geral, Gestor de Iniciativas e Programas de transformação organizacional/ operacional complexos, atuando como líder estratégico e agente de mudança, apoiando empresas na sua evolução e adaptação ao mercado.<br />Com diversos anos em Consultoria e experiência em diversas organizações nacionais e internacionais, é visto como um parceiro de confiança a nível executivo, bem como um líder na construção e motivação de equipas.<br />A sua vasta experiência internacional, demonstrando elevados níveis de conhecimento tecnológico com uma compreensão realista das tendências de mercado e visão/missão das organizações, aliado a uma especialização na visão estratégica e construção de roadmaps de transformação, são o seu melhor “cartão de visita”.<br />Dedica parte do seu tempo a apoiar startups e pequenos empresários/negócios, bem como a suportar causas de desenvolvimento e sustentabilidade social.</>
              }</p>
            </div>
          </div>

          <div className="person">
            <div className="img">
              <img src={team2} alt="" />
            </div>
            <span className="line"></span>
            <div className="content">
              <span>Francisco Oliveira</span>
              <p>{
                lang === 'en' ?
                  <>Francisco is a young man passionate about technology and innovation. From an early age, he was always curious and interested in how things worked, which led him to experiment with various tools and programming languages.<br />He is currently studying in college, while looking for ways to apply his knowledge and skills to create innovative projects. In addition, he participates in projects related to technology and computer science, which allows him to improve his skills and knowledge.<br />With several years of experience in the software development field, Francisco has expertise in web design and development. He stands out for his ability to create creative and efficient solutions, always seeking to improve his skills and offer the best in terms of quality and innovation.<br />Besides being dedicated and committed, Francisco is an entrepreneurial and innovative person. He believes that technology is fundamental for the evolution of society and is always looking for new challenges and opportunities to grow and contribute to the community.</> :
                  <>O Francisco é um jovem apaixonado por tecnologia e inovação. Desde cedo, sempre foi curioso e interessado em como as coisas funcionavam, o que o levou a experimentar diversas ferramentas e linguagens de programação.<br />Atualmente, estuda no ensino superior, enquanto procura formas de aplicar seus conhecimentos e habilidades para criar projetos inovadores. Além disso, participa em projetos relacionados com tecnologia e informática, o que lhe permite melhorar as suas habilidades e conhecimentos.<br />Com vários anos de experiência na área de desenvolvimento de software, Francisco tem expertise em design e desenvolvimento web. Destaca-se pela sua capacidade de criar soluções criativas e eficientes, procurando sempre melhorar as suas habilidades e oferecer o melhor em termos de qualidade e inovação.<br />Além de dedicado e comprometido, o Francisco é uma pessoa empreendedora e inovadora. Acredita que a tecnologia é fundamental para a evolução da sociedade e procura sempre novos desafios e oportunidades para crescer e contribuir para a comunidade.</>
              }</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  </>;
}