import { createContext } from "react";

type LangContextType = {
  lang: 'pt' | 'en';
  setLang: (lang: 'pt' | 'en') => void;
};

const LangContext = createContext<LangContextType>({
  lang: 'en',
  setLang: () => {},
});

export default LangContext;