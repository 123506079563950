import { useEffect, useState, useContext } from 'react';
import logo from '../assets/logo_white.png';
import '../styles/navbar.scss';
import en from '../assets/en.png';
import pt from '../assets/pt.png';
import LangContext from '../contexts/LangContext';

export default function Navbar({ active = 'home' }: { active: 'home' | 'sobre' | 'servicos' }) {
  const {lang, setLang} = useContext(LangContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem('preferredLang');
    if (language) setLang(language as 'en' | 'pt');
  }, [setLang]);

  return <nav>
    <div className="margin"></div>
    
    <div className={"container" + (isOpen ? ' opened' : '')}>
      <div className="content">
        <div className='top'>
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Sinformania" />
            </a>
          </div>

          <div className="menuIcon" onClick={() => setIsOpen(!isOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="right">
          <div className="links">
            <a href='/' className={"link" + (active === 'home' ? ' active' : '')}>Home</a>
            <a href='/sobre' className={"link" + (active === 'sobre' ? ' active' : '')}>{lang === 'en' ? 'About' : 'Sobre'}</a>
            <a href='/servicos' className={"link" + (active === 'servicos' ? ' active' : '')}>{lang === 'en' ? 'Services' : 'Serviços'}</a>
          </div>

          <div className="language">
            <button onClick={() => {
              setLang(lang === 'en' ? 'pt' : 'en');
              localStorage.setItem('preferredLang', lang === 'en' ? 'pt' : 'en');
            }}>
              <img src={lang === 'en' ? pt : en} alt={lang === 'en' ? 'Português' : 'English'} />
              {lang === 'en' ? 'PT' : 'EN'}
            </button>
          </div>

          <div className="contact">
            <a href="/#contactos">Contact{lang === 'en' ? ' us' : 'e-nos'}</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
}