import '../styles/home.scss';
import simplificar from '../assets/simplificar.jpg';
import formar from '../assets/formar.jpg';
import informar from '../assets/informar.jpg';
import simplificar1 from '../assets/simplificar1.jpg';
import formar1 from '../assets/formar1.jpg';
import informar1 from '../assets/informar1.jpg';
import { useContext, useEffect, useState } from 'react';
import LangContext from '../contexts/LangContext';

export default function HomePage() {
  const { lang } = useContext(LangContext);
  const [openItem, setOpenItem] = useState(0);

  useEffect(() => {
    setOpenItem(0);
  }, [lang]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpenItem((openItem + 1) % 3);
    }, 5000);

    return () => clearInterval(interval);
  }, [openItem]);

  return <>
    <section className="home__hero">
      <div className={"item" + (openItem === 0 ? ' open' : '')} onClick={() => setOpenItem(0)}>
        <img src={simplificar} alt="" />
        <span className="shadow"></span>
        <h1>{lang === 'en' ? 'Simplify' : 'Simplificar'}</h1>
        <div className="content">
          <p>{
            lang === 'en' ?
              'Innovate in a sustainable way and in accordance with the company\'s strategy.' :
              'Inovar de forma sustentável e de acordo com a estratégia da empresa.'
          }</p>
          <a href="#simplificar" className="readMore">{lang === 'en' ? 'READ MORE' : 'LER MAIS'}</a>
        </div>
      </div>

      <div className={"item" + (openItem === 1 ? ' open' : '')} onClick={() => setOpenItem(1)}>
        <img src={formar} alt="" />
        <span className="shadow"></span>
        <h1>{lang === 'en' ? 'Train' : 'Formar'}</h1>
        <div className="content">
          <p>{
            lang === 'en' ?
              'A culture of permanent challenge is what allows us to learn and improve!' :
              'Uma cultura de permanente desafio é o que nos permite aprender e melhorar!'
          }</p>
          <a href="#formar" className="readMore">{lang === 'en' ? 'READ MORE' : 'LER MAIS'}</a>
        </div>
      </div>

      <div className={"item" + (openItem === 2 ? ' open' : '')} onClick={() => setOpenItem(2)}>
        <img src={informar} alt="" />
        <span className="shadow"></span>
        <h1>{lang === 'en' ? 'Inform' : 'Informar'}</h1>
        <div className="content">
          <p>{
            lang === 'en' ?
              'Simplifying the way we communicate is vital to the success of any organization.' :
              'A simplificação da forma de comunicar é vital para o sucesso de qualquer organização.'
          }</p>
          <a href="#informar" className="readMore">{lang === 'en' ? 'READ MORE' : 'LER MAIS'}</a>
        </div>
      </div>
    </section>

    <main className="container">
      <section className="home__categs">
        <div className="categ" id="simplificar">
          <div className="img">
            <img src={simplificar1} alt="" />
          </div>
          <div className="content">
            <h3>{lang === 'en' ? 'Simplify' : 'Simplificar'}</h3>
            <p>{
              lang === 'en' ?
                "We look at our clients' challenges as if they were our own. From strategic vision to innovation, creating and/or maintaining a digital presence in a sustainable way and adequate to the size of our clients will be the key to our success!" :
                "Olhamos para os desafios dos nossos clientes como se fossem nossos. Desde a visão estratégica à inovação, criando e/ou mantendo uma presença digital de forma sustentável e adequada à dimensão dos nossos clientes será a chave do nosso sucesso!"
            }</p>
            <a className='btn' href="/servicos/#simplificar">
              {lang === 'en' ? 'KNOW MORE' : 'SABER MAIS'}
            </a>
          </div>
        </div>

        <div className="categ" id="formar">
          <div className="img">
            <img src={formar1} alt="" />
          </div>
          <div className="content">
            <h3>{lang === 'en' ? 'Train' : 'Formar'}</h3>
            <p>{
              lang === 'en' ?
                "It is crucial to keep learning throughout our lives, not just because of technological advances or for any other reason related to the job we do, but because we can and should always try to improve." :
                "É crucial continuar a aprender ao longo da nossa vida, não apenas pelos avanços tecnológicos ou por qualquer outro motivo relacionado com a função que desempenhamos, mas porque podemos e devemos tentar sempre melhorar."
            }</p>
            <a className='btn' href="/servicos/#formar">
              {lang === 'en' ? 'KNOW MORE' : 'SABER MAIS'}
            </a>
          </div>
        </div>

        <div className="categ" id="informar">
          <div className="img">
            <img src={informar1} alt="" />
          </div>
          <div className="content">
            <h3>{lang === 'en' ? 'Inform' : 'Informar'}</h3>
            <p>{
              lang === 'en' ?
                "Information, together with technological resources and means, is a necessity for the basic, strategic, and operational functioning of any company, and is fundamental to keep the focus of those who receive it and have to act on it." :
                "A informação, em conjunto com recursos e meios tecnológicos, é uma necessidade para o funcionamento básico, estratégico e operacional de qualquer empresa, sendo fundamental para manter o foco de quem a recebe e tem que agir."
            }</p>
            <a className='btn' href="/servicos/#informar">
              {lang === 'en' ? 'KNOW MORE' : 'SABER MAIS'}
            </a>
          </div>
        </div>
      </section>

      <section className="home__contact" id='contactos'>
        <div className="content">
          <h3>{lang === 'en' ? 'Contact us' : 'Contacte-nos'}</h3>
          <p>{
            lang === 'en' ?
              'We are always available to answer any questions you may have.' :
              'Estamos sempre disponíveis para responder a qualquer questão que possa ter.'
          }</p>
          <div className="contacts">
            <a href="https://www.linkedin.com/company/sinformania/" target={'_blank'} rel='noreferrer'>
              <i className="bx bxl-linkedin" />
              <span>LINKEDIN</span>
              <span>sinformania</span>
            </a>
            <a href="mailto:geral@sinformania.pt">
              <i className="bx bxs-envelope" />
              <span>EMAIL</span>
              <span>geral@sinformania.pt</span>
            </a>
          </div>
        </div>
      </section>
    </main>
  </>;
}