import '../styles/footer.scss';
import logo from '../assets/logo_white.png';
import linkedin from '../assets/linkedin.svg';
import { useContext } from 'react';
import LangContext from '../contexts/LangContext';

export default function Footer() {
  const {lang} = useContext(LangContext);

  return <footer>
    <div className="container">
      <div className="content">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Sinformania" />
          </a>
        </div>

        <div className="links">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/sobre">{lang === 'en' ? 'About' : 'Sobre'}</a></li>
            <li><a href="/servicos">{lang === 'en' ? 'Services' : 'Serviços'}</a></li>
          </ul>
        </div>

        <div className="email">
          <a href="mailto:geral@sinformania.pt">
            geral@sinformania.pt
          </a>
        </div>

        <div className="social">
          <a href="https://www.linkedin.com/company/sinformania/" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="Linkedin" />
          </a>
        </div>
      </div>
    </div>
  </footer>
}