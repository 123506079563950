import React, { useState } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import LangContext from './contexts/LangContext';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';

function App() {
  const [lang, setLang] = useState<'pt' | 'en'>('pt');

  return (
    <>
      <LangContext.Provider value={{ lang, setLang }}>

        <BrowserRouter>
          <Routes>
            <Route path='/' element={<>
              <Navbar active='home' />
              <HomePage />
            </>} />
            <Route path='/sobre' element={<>
              <Navbar active='sobre' />
              <AboutPage />
            </>} />
            <Route path='/servicos' element={<>
              <Navbar active='servicos' />
              <ServicesPage />
            </>} />
            <Route path='*' element={<>
              <Navbar active='home' />
              <HomePage />
            </>} />
            
            {/* <main className="container">
            </main> */}
          </Routes>
        </BrowserRouter>

        <Footer />
      </LangContext.Provider>
    </>
  );
}

export default App;
