import '../styles/services.scss';
import { useContext } from 'react';
import LangContext from '../contexts/LangContext';
import banner from '../assets/services-banner.jpg';

export default function ServicesPage() {
  const { lang } = useContext(LangContext);

  return <>
    <div className="services__banner">
      <img src={banner} alt="" />
      <span className="shadow"></span>

      <div className="container">
        <h2>{lang === 'en' ? 'Services' : 'Serviços'}</h2>
        <span className="line"></span>
        <p>{
          lang === 'en' ?
            "We seek to implement a continuous improvement mentality from a professional (including the individual and the organization) and personal (in everything we do or intend to do in our daily lives) point of view. The habit to Simplify, Train and Inform is part of our DNA!" :
            "Procuramos implementar uma mentalidade de melhoria contínua de um ponto de vista profissional (incluindo o individuo e a organização) e pessoal (em tudo o que fazemos ou pretendemos fazer no nosso dia-a-dia). A mania de Simplificar, Formar e Informar faz parte do nosso ADN!"
        }</p>
        <div className="buttons">
          <a href='#simplificar' className="btn">{lang === 'en' ? 'Simplify' : 'Simplificar'}</a>
          <span className="bullet"></span>
          <a href='#formar' className="btn">{lang === 'en' ? 'Train' : 'Formar'}</a>
          <span className="bullet"></span>
          <a href='#informar' className="btn">{lang === 'en' ? 'Inform' : 'Informar'}</a>
        </div>
      </div>
    </div>

    <main className="container">
      <section className="services__services" id='simplificar'>
        <div className="heading">
          <h2>{lang === 'en' ? 'Simplify' : 'Simplificar'}</h2>
          <span className="line"></span>
          <p>{
            lang === 'en' ?
              "Make it simple! Being able to explain to a 6-year-old child what we mean is one of the great challenges of our professional and personal journey. When we face such a need, we are not always able to think differently, to innovate, to implement something that is sustainable and lasting, to define a strategy to address the problem... We believe we are the ideal partner to support organizations in defining, implementing and communicating their vision!" :
              "Tornar mais simples! Ser capaz de explicar a uma criança de 6 anos o que queremos dizer é um dos grandes desafios do nosso percurso profissional e pessoal. Quando nos deparamos com tal necessidade, nem sempre somos capazes de pensar de forma diferente, de inovar, de implementar algo que seja sustentável e duradouro, de definir uma estratégia para abordar o problema... Consideramos que somos o parceiro ideal para apoiar as organizações a definir, implementar e comunicar a sua visão!"
          }</p>
        </div>

        <div className="services">
          <div className="service">
            <div className="head">
              <i className='bx bxs-chess' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Strategy Definition' : 'Definição de Estratégia'}</span>
              <p>{
                lang === 'en' ?
                  "Defining and implementing an organization's strategy is crucial to its success. How the organization is perceived, internally and externally, is directly related to the results of the transformation program." :
                  "Definir e implementar a estratégia de uma organização é crucial para o seu sucesso. A forma como a organização é vista, interna e externamente, está diretamente relacionada com os resultados do programa de transformação."
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bx-recycle' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Sustainability' : 'Sustentabilidade'}</span>
              <p>{
                lang === 'en' ?
                  "Intervene in the relationship of the human being with the world in a permanent way: make a difference! Sustainable solutions allow us to predict results and plan the evolution of an organization." :
                  "Intervir na relação do ser humano com o mundo de forma permanente: fazer a diferença! Soluções sustentáveis permitem prever resultados e planear a evolução de uma organização."
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bx-bulb' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Inovation' : 'Inovação'}</span>
              <p>{
                lang === 'en' ?
                  "It is difficult to get out of our comfort zone and think differently. Taking something that exists and suggesting improvements or proposing something new is not part of our daily routine. Questioning the Status Quo is increasingly fundamental to the success of an organization." :
                  "Sair da nossa zona de conforto e pensar de forma diferente é dificil. Pegar em algo que exista e sugerir melhorias ou propor algo novo não faz parte do nosso dia-a-dia. Questionar o Status Quo é cada vez mais fundamental para o sucesso de uma organização."
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bx-calendar-event' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Events Organization' : 'Organização de Eventos'}</span>
              <p>{
                lang === 'en' ?
                  "The social aspect is fundamental to our personal and professional motivation. An event that challenges us, exposes us, and allows us to get to know the human side of others makes us more human ourselves!" :
                  "A vertente social é fundamental para a nossa motivação pessoal e profissional. Um evento que nos desafie, nos exponha e nos permita conhecer o lado humano dos outros torna-nos a nós próprios mais humanos!"
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bx-globe' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Website Creation and Maintenance' : 'Criação e Manutenção de Websites'}</span>
              <p>{
                lang === 'en' ?
                  "The digital identity of an organization often defines the perception that exists about the added value of products and/or services. It is fundamental to its own success." :
                  "A identidade digital de uma organização define muitas vezes a perceção que existe sobre o valor acrescentado dos produtos e/ou serviços. É fundamental para o seu próprio sucesso."
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bx-user-circle' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Customized Products' : 'Produtos Personalizados'}</span>
              <p>{
                lang === 'en' ?
                  "If we want to make a difference, we should promote it! We all like to feel that we are \"special\" and that something was made just for us. A personalized product or service makes us feel \"the center of the world\"!" :
                  "Se queremos fazer a diferença, devemos promovê-la! Todos nós gostamos de sentir que somos \"especiais\" e que algo foi feito a pensar em nós. Um produto ou serviço personalizado faz-nos sentir \"o centro do mundo\"!"
              }</p>
            </div>
          </div>
        </div>
      </section>

      <div className="services__services" id="formar">
        <div className="heading">
          <h2>{lang === 'en' ? 'Train' : 'Formar'}</h2>
          <span className="line"></span>
          <p>{
            lang === 'en' ?
              <>Companies that support and invest in the training of their workforce clearly have a competitive advantage when it comes to attracting and retaining talent, as they demonstrate a genuine commitment to their employees.<br />Social learning will not only allow professionals to meet others in fields not directly related to their own, it will also provide access to a broader set of knowledge.</> :
              <>As empresas que apoiam e investem na formação da sua força de trabalho têm claramente uma vantagem competitiva no que diz respeito à captação e retenção de talento, já que demonstram um compromisso genuíno para com os seus empregados.<br />A aprendizagem social não só permitirá que os profissionais conheçam outras pessoas de áreas não diretamente relacionadas com as suas, como permitirá também o acesso a um conjunto mais amplo de conhecimentos.</>
          }</p>
        </div>

        <div className="services">
          <div className="service">
            <div className="head">
              <i className='text'>01</i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Behavioral' : 'Comportamental'}</span>
              <p>{
                lang === 'en' ?
                  "Learning to enhance our communication or presentation skills, learning meeting / workshop management techniques, performing role-play activities are just a few examples of other activities that are not always on the \"agenda\" of the organizations where we work, but that should be present in our evolution as professionals and human beings." :
                  "Aprender a potenciar as nossas capacidades de comunicação ou apresentação, aprender técnicas de gestão de reuniões / workshops, executar atividades de role-play são apenas alguns exemplos de outras atividades que nem sempre estão na \"ordem do dia\" das organizações onde trabalhamos, mas que devem estar presentes na nossa evolução, enquanto profissionais e seres humanos."
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='text'>02</i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Technical' : 'Técnica'}</span>
              <p>{
                lang === 'en' ?
                  "With the continuous introduction of technology in our daily lives, we are not always able to have the availability and/or capacity to learn on our own what can be very simple and give us the foundations we need. We propose to support the acquisition of these concepts so that you can make a difference and not feel excluded." :
                  "Com a contínua introdução da tecnologia no nosso dia-a-dia, nem sempre conseguimos ter a disponibilidade e/ou capacidade para aprender sozinhos o que pode ser muito simples e dar-nos as bases de que precisamos. Propomo-nos apoiar a aquisição desses conceitos para que possa fazer a diferença e não se sentir excluído/a."
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='text'>03</i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Personal' : 'Pessoal'}</span>
              <p>{
                lang === 'en' ?
                  "Consolidating personal knowledge or acquiring new knowledge, focused on one's way of being, is sometimes more important than trying to learn everything. Individual talent allows us to stand out from the rest! This personal and personalized accompaniment is what we propose to do." :
                  "A consolidação dos conhecimentos pessoais ou aquisição de novos, focados na maneira de ser de cada um, é, por vezes, mais importante do que tentar aprender tudo. O talento individual permite que nos destaquemos dos demais! Este acompanhamento pessoal e personalizado é o que nos propomos fazer."
              }</p>
            </div>
          </div>
        </div>
      </div>

      <div className="services__services" id="informar">
        <div className="heading">
          <h2>{lang === 'en' ? 'Inform' : 'Informar'}</h2>
          <span className="line"></span>
          <p>{
            lang === 'en' ?
              <>The need to get feedback from employees and/or users, to be able to communicate important information in a quick and assertive way, to avoid the huge amount of emails that are not always read with the proper attention, sometimes forces us to define the entire corporate communication strategy. It becomes evident that, more important than sending a lot of information, it is fundamental to be able to capture someone's attention with a simple message: A simple notification that cannot be ignored and that conveys clear and concise information.</> :
              <>A necessidade de obter feedback dos colaboradores e/ou utilizadores, de poder dar a conhecer informação importante de forma ágil e assertiva, de evitar a enorme quantidade de emails, que nem sempre são lidos com a devida atenção, obriga-nos, por vezes, a definir toda a estratégia de comunicação corporativa. Torna-se assim evidente que, mais importante do que enviar muita informação, é fundamental ser capaz de captar a atenção de alguém com uma simples mensagem: Uma simples notificação que não possa ser ignorada e que passe uma informação clara e concisa.</>
          }</p>
        </div>

        <div className="services">
          <div className="service">
            <div className="head">
              <i className='bx bx-devices' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Digital Content' : 'Conteúdos Digitais'}</span>
              <p>{
                lang === 'en' ?
                  <><span></span> Flyers<br /><span></span> Posters<br /><span></span> Videos<br /><span></span> Banners<br /><span></span> More</> :
                  <><span></span> Flyers<br /><span></span> Posters<br /><span></span> Vídeos<br /><span></span> Banners<br /><span></span> Mais</>
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bxs-megaphone' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Communication Strategy' : 'Estratégia de Comunicação'}</span>
              <p>{
                lang === 'en' ?
                  <><span></span> Target Audience<br /><span></span> Contents<br /><span></span> Channels (App / Website)<br /><span></span> Benchmarks</> :
                  <><span></span> Público Alvo<br /><span></span> Conteúdos<br /><span></span> Canais (App / Website)<br /><span></span> Benchmarks</>
              }</p>
            </div>
          </div>

          <div className="service">
            <div className="head">
              <i className='bx bx-buildings' ></i>
            </div>
            <div className="content">
              <span>{lang === 'en' ? 'Corporate Communications' : 'Comunicação Corporativa'}</span>
              <p>{
                lang === 'en' ?
                  <><span></span> Channels (App / Website)<br /><span></span> Identity<br /><span></span> Content Management<br /><span></span> Design</> :
                  <><span></span> Canais (App / Website)<br /><span></span> Identidade<br /><span></span> Gestão de Conteúdos<br /><span></span> Design</>
              }</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </>;
}